import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { getAboutUsContent } from '../../../requests'

const useStyles = makeStyles(theme => ({
  fullPage: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',

  },
  name: {
    fontSize: '80px',
    margin: '50px 50px 40px 50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      margin: '40px 20px 10px 20px'
    }
  },
  about_uses: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 60px',
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
      fontSize: '20px'
    }
  },
  about: {
    display: 'flex',
    margin: '45px 0'
  },
  number: {
    border: `3px solid ${theme.palette.primary.main}`,
    textAlign: 'center',
    padding: '20px',
    margin: '0 40px',
    borderRadius: '40%',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px'
    }
  },
  divider: {
    margin: '0px 80px 0 80px',
    padding: '10px 0',
    background: `${theme.palette.primary.main}`,
    [theme.breakpoints.down('sm')]: {
      margin: '0px 45px 0 45px'
    }
  }
}))
const AboutUs = () => {
  const classes = useStyles()
  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))
  const [content, setContent] = useState({})
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getAboutUsContent(state.currentLanguage)
      setContent(data)
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])

  return (
    <>
      {content.display && (
        <div className={classes.fullPage} id={content.menu_destination}>
          <Typography variant='h2' className={classes.name}>
            {content.title}:
          </Typography>
          <div className={classes.about_uses}>
            {content.about_uses?.data.map((el, index) => (
              <div className={classes.about}>
                <div>
                  <div className={classes.number}>0{index + 1}</div>
                  <Divider
                    className={classes.divider}
                    orientation='vertical'
                    light={true}
                  />
                </div>
                <div>{el.attributes.info}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default AboutUs
