import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../home_page/content/Footer'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import SwipeableViews from 'react-swipeable-views'

import { getMenuItems, getCategories, getHeaderContent } from '../../requests'
import SelectLanguage from '../../components/navbar/select_language/SelectLanguage'
import LogoElement from '../../components/logo/Logo'
import AllergensElement from '../../components/allergens/Allergens'

const useStyles = makeStyles(theme => ({
  fullPage: {
    display: 'flex',
    minHeight: '65vh',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '130px',
    margin: '100px 10px 0 10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '110px',
      alignItems: 'unset'
    }
  },
  cardContainer: {
    display: 'grid',
    margin: '10px 0px',
    gridTemplateColumns: '50% 50%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      margin: '10px 15px'
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '10px 20px',
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: '3px',
    alignSelf: 'end',
    minWidth: '320px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '170px',
      margin: '0'
    }
  },
  image: {
    width: '250px',
    [theme.breakpoints.down('sm')]: {
      width: '150px'
    }
  },
  textTitle: {
    fontSize: '20px',
    color: theme.palette.secondary.main
  },
  textPrice: {
    color: theme.palette.secondary.light,
    fontSize: '14px',
    placeSelf: 'center',
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0, 0.5),
    background: theme.palette.secondary.main,
    borderRadius: '10px'
  },
  buttonTab: {
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '10px 10px 0 0'
  },
  ingridientsHolder: {
    fontSize: '15px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  priceAndName: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  products: {
    margin: theme.spacing(2, 1),
    width: '100%'
  },
  languageSelect: {
    display: 'flex',
    position: 'fixed',
    height: 'max-content',
    zIndex: 20,
    top: 20,
    right: 0,
    bottom: 0
  },
  allergens: {
    display: 'flex'
  },
  allergen: {
    padding: theme.spacing(0, 0.1)
  },
  buttonList: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonFromList: {
    margin: '5px 0'
  }
}))
const api_url = process.env.REACT_APP_API_URL

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  )
}
const ButtonHandlers = {
  en: 'Close',
  bg: 'Затвори',
  ru: 'Закрыть'
}
const headers = {
  en: 'Categories',
  bg: 'Категории',
  ru: 'Категории'
}
const Menu = () => {
  const classes = useStyles()
  const theme = useTheme()

  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [content, setContent] = useState({})
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const categories = await getCategories()
      setCategories(categories)
      const productResponse = await getMenuItems(categories[0].id)
      const header = await getHeaderContent(state.currentLanguage)
      setContent(header)
      setProducts(productResponse)
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])
  const [value, setValue] = React.useState(0)

  const handleChange = async (newValue, categoryId) => {
    setValue(newValue)
    const productResponse = await getMenuItems(categoryId)
    setProducts(productResponse)
    setOpen(false)
  }
  const handleChangeIndex = index => {
    setValue(index)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <LogoElement logoUrl={content.logo?.data.attributes.url} />
      <div className={classes.languageSelect}>
        <AllergensElement />
        <SelectLanguage />
      </div>
      <div className={classes.fullPage}>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
        >
          {categories.map((el, index) => (
            <Tab
              key={el}
              label={el}
              {...a11yProps(index)}
              className={classes.buttonTab}
            />
          ))}
        </Tabs> */}
        <Button
          variant='contained'
          onClick={handleClickOpen}
          size='large'
          color='secondary'
        >
          {headers[state.currentLanguage]}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: theme.palette.background.default
            }
          }}
        >
          <DialogTitle>{headers[state.currentLanguage]}:</DialogTitle>
          <DialogContent className={classes.buttonList}>
            {categories.map((el, index) => (
              <Button
                variant='contained'
                color='secondary'
                className={classes.buttonFromList}
                onClick={() => handleChange(index, el.id)}
              >
                {el.attributes[`title_${state.currentLanguage}`]}
              </Button>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='default'>
              {ButtonHandlers[state.currentLanguage]}
            </Button>
          </DialogActions>
        </Dialog>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {categories.sort((a,b) => a.attributes.order - b.attributes.order).map((el, index) => (
            <TabPanel value={value} index={index} dir={theme.direction}>
              <div className={classes.cardContainer}>
                {products.map(element => (
                  <div className={classes.card}>
                    {element.attributes?.image?.data && (
                      <img
                        src={`${api_url}${element.attributes.image?.data?.attributes.url}`}
                        alt={`${
                          element.attributes[`title_${state.currentLanguage}`]
                        }`}
                        className={classes.image}
                      />
                    )}
                    <div className={classes.priceAndName}>
                      <div className={classes.allergens}>
                        <Typography className={classes.textTitle}>
                          {element.attributes[`title_${state.currentLanguage}`]}
                        </Typography>
                        {element.attributes?.allergens?.data?.length > 0 &&
                          element.attributes?.allergens?.data?.map(el => (
                            <div className={classes.allergen}>{el.id}</div>
                          ))}
                      </div>

                      <Typography className={classes.textPrice}>
                        {element.attributes?.milliliters &&
                          `${element.attributes.milliliters} ml /`}
                        {element.attributes?.grams &&
                          `${element.attributes.grams} gr /`}
                        {element.attributes?.price || 0} BGN
                      </Typography>
                    </div>

                    {element.attributes?.ingridients?.data?.length > 1 && (
                      <div className={classes.products}>
                        <div className={classes.ingridientsHolder}>
                          {element.attributes?.ingridients?.data.map(
                            ingridient => (
                              <Typography variant='body2'>
                                {
                                  ingridient.attributes[
                                    `title_${state.currentLanguage}`
                                  ]
                                }{' '}
                                &#x2022;{' '}
                              </Typography>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </TabPanel>
          ))}
        </SwipeableViews>
      </div>
      <Footer />
    </>
  )
}

export default Menu
