import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'

import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline } from '@material-ui/core'

import { themesConfig } from '../themes/main'
import { Routes } from '../components/routes'
import { setCurrentPage } from '../components/navbar/store/current_page/CurrentPageActions'
import { PageNotifications } from '../components/notifications/notificationsService'
// import Loading from "../components/loading/Loading";
import '../themes/Main.scss'

const App = ({ _, history }) => {
  const state = useSelector(state => ({
    content: state.getContent.content,
    showNotificationMessage: state.showNotification.showNotificationMessage
  }))
  const theme = createTheme(themesConfig)
  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    let pathname
    try {
      pathname = Routes.filter(el => el.path === history.location.pathname)[0]
        .tabName
    } catch {
      pathname = 'Not Found'
    }
    document.title = pathname
    dispatch(setCurrentPage(history.location.pathname))
  }, [history.location.pathname])

  // useEffect(() => {
  //   const timeoutID = window.setTimeout(() => {
  //     setLoading(() => true)
  //   }, 2000)

  //   return () => window.clearTimeout(timeoutID)
  // }, [])

  const routeComponents = Routes.map(({ path, Component }, key) => (
    <Route
      exact
      path={path}
      key={key}
      render={props => <Component {...props} />}
    />
  ))
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {state.showNotificationMessage && <PageNotifications />}
        {/* {loading ?  <Switch>{routeComponents}</Switch> : <Loading />} */}
        <Switch>{routeComponents}</Switch>
       
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default withRouter(App)
