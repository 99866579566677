import React from 'react'

import NavBar from '../../components/navbar'
import Header from './content/Header'
import AboutUs from './content/AboutUs'
// import MenuMain from './content/Menu'
import Events from './content/Events'
import ContactUs from './content/ContactUs'
import Footer from './content/Footer'
const Home = () => {
  return (
    <>
      <NavBar />
      <Header />
      {/* <MenuMain /> */}
      <Events />
      <AboutUs />
      <ContactUs />
      <Footer />
    </>
  )
}

export default Home
