import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { getHeaderContent } from '../../../requests'
import LogoElement from '../../../components/logo/Logo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import { getMenuContent } from '../../../requests'

const api_url = process.env.REACT_APP_API_URL

const useStyles = makeStyles(theme => ({
  fullPage: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  name: {
    fontSize: '250px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '70px'
    }
  },
  logo: {
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    width: '620px',
    opacity: 0.8,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      opacity: 0.7
    }
  },
  button: {
    position: 'absolute',
    bottom: '50px',
    [theme.breakpoints.down('sm')]: {
      bottom: '100px'
    }
  },
  logoSize: {
    width: '620px',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      width: '320px',
      opacity: 0.7
    }
  }
}))
const Header = () => {
  const classes = useStyles()
  const [content, setcontent] = useState({})
  const [menuContent, setMenuContent] = useState({})
  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))
  const history = useHistory()
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getHeaderContent()
      setcontent(data)
      const menuData = await getMenuContent(state.currentLanguage)
      setMenuContent(menuData)
    }
    // call the function
    fetchData().catch(console.log('error Header'))
  }, [state.currentLanguage])

  const handleClick = event => {
    history.push('/menu')
  }
  return (
    <>
      <LogoElement
        logoUrl={content.logo?.data.attributes.url}
        pathname={content.menu_destination}
      />
      <div className={classes.fullPage} id={content.menu_destination}>
        <Typography variant='h1' className={classes.name}>
          {content.name}
        </Typography>
        <div className={classes.logo}>
          <LazyLoadImage
            alt='loading ...'
            effect='blur'
            className={classes.logoSize}
            src={`${api_url}${content.image?.data.attributes.url}`}
          />
        </div>
        <Button
          variant='contained'
          className={classes.button}
          color='primary'
          onClick={handleClick}
        >
          <Typography variant='h5'>{menuContent.description}</Typography>
        </Button>

        {/* <img
          src={`${api_url}${content.image?.data.attributes.url}`}
          alt='loading ...'
          className={classes.logo}
        /> */}
      </div>
    </>
  )
}

export default Header
