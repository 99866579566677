import HomePage from "../../containers/home_page/Home";
import Menu from "../../containers/menu/Menu"
import NotFound from "../not_found/NotFound"


export const Routes = [
  {
    name: "HOME",
    tabName: "Adenium - HOOKAH BAR",
    type: "main",
    path: "/",
    Component: HomePage,
  },
  {
    name: "MENU",
    tabName: "Adenium - MENU",
    type: "main",
    path: "/menu",
    Component: Menu,
  },
  {
    name: "Not Found",
    type: "notInTheMenu",
    path: "*",
    Component: NotFound,
  },
];
