import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MdPhone from '@material-ui/icons/Phone'
import InstagramIcon from '@material-ui/icons/Instagram'
import Chip from '@material-ui/core/Chip'
import FacebookIcon from '@material-ui/icons/Facebook'

import { getContactUsContent } from '../../../requests'

const useStyles = makeStyles(theme => ({
  fullPage: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  name: {
    fontSize: '80px',
    margin: '50px 50px 40px 50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      margin: '40px 20px 10px 20px'
    }
  },
  chips: {
    maxWidth: '250px',
    minWidth: '250px',
    margin: '10px 0',
    fontSize: '18px'
  },
  maps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    justifySelf: 'center',
    textAlign: 'center'
  },
  mapsText: {
    width: '640px',
    height: '480px',
    margin: '10px',
    justifySelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '340px',
      height: '280px'
    }
  },
  info: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%'
    }
  },
  infoMarkers: {
    display: 'grid',
    justifyItems: 'center',
    margin: '100px 120px',
    [theme.breakpoints.down('sm')]: {
      margin: '20px 10px'
    }
  },
  address: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}))
const ContactUs = () => {
  const classes = useStyles()
  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))
  const [content, setContent] = useState({})
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getContactUsContent(state.currentLanguage)
      setContent(data)
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])

  return (
    <>
      {content.display && (
        <div className={classes.fullPage} id={content.menu_destination}>
          <Typography variant='h2' className={classes.name}>
            {content.title}:
          </Typography>
          <div className={classes.info}>
            <div className={classes.infoMarkers}>
              <Chip
                clickable
                color='primary'
                onClick={() => window.open(`tel:${content.phone_number}`)}
                className={classes.chips}
                icon={<MdPhone />}
                label={content.phone_number}
              />
              {/* <Chip
                clickable
                color='inherit'
                className={classes.chips}
                onClick={() =>
                  window.open(`tel:${content.additional_phone_number}`)
                }
                icon={<MdPhone />}
                label={content.additional_phone_number}
              /> */}
              <Chip
                clickable
                color='default'
                onClick={() => window.open(content.instagram)}
                className={classes.chips}
                icon={<InstagramIcon />}
                label='Instagram'
              />
              <Chip
                clickable
                color='default'
                onClick={() => window.open(content.facebook)}
                className={classes.chips}
                icon={<FacebookIcon />}
                label='Facebook'
              />
            </div>
            <div className={classes.maps}>
              <iframe
                title='1'
                src='https://www.google.com/maps/d/embed?mid=1mLIH1ICKNTWlppynnvV3Q6L47RHchAE&ehbc=2E312F&noprof=1'
                className={classes.mapsText}
              ></iframe>
              <Typography
                variant='h5'
                className={classes.address}
                onClick={() =>
                  window.open(
                    'https://www.google.com/maps/dir//ulitsa+%E2%80%9EGeorgi+S.+Rakovski%E2%80%9C+112,+1000+Sofia+Center,+Sofia/@42.6919866,23.2432195,12z'
                  )
                }
              >
                {content.address}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ContactUs
