import React from 'react'

import { Link } from 'react-router-dom'
import { Link as LocalLink } from 'react-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    // TODO
    background: theme.palette.background.default,
    zIndex: 20,
    top: 0,
    left: 0,
    padding: theme.spacing(1)
  },
  logo: {
    width: '140px',
    [theme.breakpoints.down('md')]: {
      width: '140px'
    }
  }
}))

const LogoElement = ({ logoUrl, pathname = 'home' }) => {
  const classes = useStyles()
  const history = useHistory()
  const api_url = process.env.REACT_APP_API_URL
  return (
    <div className={classes.root}>
      {history.location.pathname === '/' ? (
        <LocalLink
          className={classes.menuElement}
          to={pathname}
          spy={true}
          smooth={true}
          offset={-20}
          duration={500}
        >
          <LazyLoadImage
            alt='logo'
            effect='blur'
            src={`${api_url}${logoUrl}`}
            className={classes.logo}
          />
        </LocalLink>
      ) : (
        <Link className={classes.menuElement} to={'/'}>
          <LazyLoadImage
            alt='logo'
            effect='blur'
            src={`${api_url}${logoUrl}`}
            className={classes.logo}
          />
        </Link>
      )}
    </div>
  )
}

export default LogoElement
