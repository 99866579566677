export const getAllContent = async (lang = `bg`) => {
  try {
    const res = await Promise.all([
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-header?populate=*&locale=${lang}`
      ),
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-menu?populate=*&locale=${lang}`
      ),
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-event?populate=*&locale=${lang}`
      ),
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-reservation?populate=*&locale=${lang}`
      ),
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-about-us?populate=*&locale=${lang}`
      ),
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-contact-us?populate=*&locale=${lang}`
      ),
      fetch(
        `${process.env.REACT_APP_API_URL}/api/content-footer?populate=*&locale=${lang}`
      )
    ])
    const data = await Promise.all(res.map(r => r.json()))
    const contetnt = data.map(el => el.data?.attributes || [])
    return contetnt
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getHeaderContent = async (lang = 'bg') => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/content-header?populate=*&locale=${lang}`
    ).then(r => r.json())

    return req.data?.attributes || {}
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getAboutUsContent = async (lang = 'bg') => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/content-about-us?populate=*&locale=${lang}`
    ).then(r => r.json())

    return req.data?.attributes || {}
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getMenuContent = async (lang = 'bg') => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/content-menu?populate=*&locale=${lang}`
    ).then(r => r.json())

    return req.data?.attributes || {}
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getEventsContent = async (lang = 'bg') => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/content-event?populate=*&locale=${lang}`
    ).then(r => r.json())

    const events = await fetch(
      `${process.env.REACT_APP_API_URL}/api/events?populate=*&locale=${lang}`
    ).then(r => r.json())
    const response = { info: req.data, events: events }
    return response
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getContactUsContent = async (lang = 'bg') => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/content-contact-us?populate=*&locale=${lang}`
    ).then(r => r.json())

    return req.data?.attributes || {}
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getFooterContent = async (lang = 'bg') => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/content-footer?populate=*&locale=${lang}`
    ).then(r => r.json())

    return req.data?.attributes || {}
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getMenuItems = async (id = 0) => {
  try {
    const { data } = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products?populate=*&filters[category][id][$eq]=${id}&pagination[limit]=100`
    ).then(r => r.json())
    return data
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getCategories = async () => {
  try {
    const { data } = await fetch(
      `${process.env.REACT_APP_API_URL}/api/categories`
    ).then(r => r.json())
    return data
  } catch {
    throw Error(`Promise failed`)
  }
}

export const getAllergens = async () => {
  try {
    const req = await fetch(
      `${process.env.REACT_APP_API_URL}/api/allergens?populate=*`
    ).then(r => r.json())
    return req.data
  } catch {
    throw Error(`Promise failed`)
  }
}
