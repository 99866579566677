import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { getFooterContent } from '../../../requests'
import { Divider } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  fullPage: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px'
  },
  name: {
    fontSize: '50px',
    margin: '50px 50px 40px 50px',
    textAlign: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      margin: '40px 20px 10px 20px'
    }
  },
  disclaimer:{
    display: "flex",
    justifyContent: "center",
    textAlign: 'center',
    margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },

  }
}))
const Footer = () => {
  const classes = useStyles()
  const year = new Date().getFullYear();

  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))
  const [content, setcontent] = useState({})
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getFooterContent(state.currentLanguage)
      setcontent(data)
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])

  return (
    <>
      {content.display && (
        <div className={classes.fullPage}>
          <Typography variant='h4' className={classes.name}>
            {content.title}
          </Typography>
          <Divider />
          <div className={classes.disclaimer}>
            <Typography variant='body2'>
              {year === 2024 ? year : `2024 - ${year}`} | &copy; All rights reserved
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
