import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { getAllergens } from '../../requests'

const useStyles = makeStyles(theme => ({
  currentLanguage: {
    padding: theme.spacing(1, 0)
  }
}))

const headers = {
  en: 'Allergens',
  bg: 'Алергени',
  ru: 'Аллергены'
}
const ButtonHandlers = {
  en: 'Close',
  bg: 'Затвори',
  ru: 'Закрыть'
}
const AllergensElement = () => {
  const classes = useStyles()
  const theme = useTheme()

  const [open, setOpen] = useState(false)
  const [allergens, setAllergens] = useState([])

  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getAllergens()
      setAllergens(data)
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <Button color='default' onClick={handleClickOpen}>
        <span className={classes.currentLanguage}>
          <Typography variant='body2' noWrap>
            {headers[state.currentLanguage]}
          </Typography>
        </span>
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.background.default
          }
        }}
      >
        <DialogTitle onClose={handleClose}>
          {headers[state.currentLanguage]}
        </DialogTitle>
        <DialogContent>
          {allergens.map(element => (
            <>
              <Typography variant='body2'>
                {element?.id} - {element?.attributes[`title_${state.currentLanguage}`]}
              </Typography>
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            {ButtonHandlers[state.currentLanguage]}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AllergensElement
