import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { getEventsContent } from '../../../requests'

const api_url = process.env.REACT_APP_API_URL
const useStyles = makeStyles(theme => ({
  fullPage: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px'
  },
  name: {
    fontSize: '80px',
    margin: '80px 50px 40px 50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
      margin: '60px 20px 10px 20px'
    }
  },
  cardsHolder: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  card: {
    maxWidth: 325,
    height: 'max-content',
    margin: '10px'
  }
}))
const Events = () => {
  const classes = useStyles()
  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))

  const [content, setContent] = useState({})
  const [events, setEvents] = useState([])

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getEventsContent(state.currentLanguage)

      setContent(data.info.attributes)
      setEvents(data.events.data)
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])

  return (
    <>
      {content.display && content.events.data.length > 0 && (
        <div className={classes.fullPage} id={content.menu_destination}>
          <Typography variant='h2' className={classes.name}>
            {content.title}:
          </Typography>
          <div className={classes.cardsHolder}>
            {events.map((event, index) => (
              <Card className={classes.card}>
                <CardMedia
                  component='img'
                  // height='140'
                  image={`${api_url}${event.attributes.image?.data?.attributes?.url}`}
                  alt='green iguana'
                />
                <CardContent>
                  <Typography gutterBottom variant='h5' component='div'>
                    {event.attributes[`title_${state.currentLanguage}`]}
                  </Typography>
                  <Typography variant='body1' color='text.secondary'>
                    {new Date(event.attributes?.date).toLocaleString([], {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </Typography>
                  <br />
                  <Typography variant='body2' color='text.secondary'>
                    {event.attributes[`description_${state.currentLanguage}`]}
                  </Typography>
                  <br />
                </CardContent>
                {event.attributes[`button_title_${state.currentLanguage}`] && (
                  <CardActions>
                    <Button size='small' href={event.attributes.button_link}>
                      {event.attributes[`button_title_${state.currentLanguage}`]}
                    </Button>
                  </CardActions>
                )}
              </Card>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Events
