import React, { useState, useEffect } from 'react'
import { Squash as Hamburger } from 'hamburger-react'
import clsx from 'clsx'
import { Link } from 'react-scroll'
import { useSelector } from 'react-redux'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { getAllContent } from '../../requests'
import SelectLanguage from './select_language/SelectLanguage'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    height: 'max-content',
    zIndex: 30,
    top: 0,
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      top: 'auto',
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  menuHolder: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: 0,
    zIndex: -1,
    bottom: 0,
    background: theme.palette.primary.main,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    })
  },
  openMenu: {
    width: '300px',
    boxShadow: `-9px 0px 23px 0px ${theme.palette.background.default}`
  },
  menuItems: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity .25s'
  },
  showText: {
    opacity: 1
  },
  activeMenu: {
    color: theme.palette.secondary.main
  },
  menuElement: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    padding: theme.spacing(1, 2),
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  menu: {
    display: 'flex',
    height: 'max-content',
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0
    }
  }
}))

const NavBar = () => {
  const classes = useStyles()
  const theme = useTheme()
  const state = useSelector(state => ({
    content: state.getContent.content,
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))

  const [isOpen, setOpen] = useState(false)
  const [pageNav, setPageNav] = useState([])
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await getAllContent(state.currentLanguage)
      setPageNav(
        data
          .filter(
            el =>
              el.menu_destination !== undefined &&
              el.display &&
              el.menu_destination !== 'menu'
          )
          .map(el => {
            return { name: el.title, to: el.menu_destination }
          })
      )
    }
    // call the function
    fetchData()
  }, [state.currentLanguage])

  return (
    <>
      <div className={classes.root}>
        <div className={classes.menu}>
          <div className={classes.content}>
            <SelectLanguage />
          </div>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color={theme.palette.secondary.contrastText}
          />
          <div
            className={clsx(classes.menuHolder, {
              [classes.openMenu]: isOpen
            })}
          >
            <div
              className={clsx(classes.menuItems, {
                [classes.showText]: isOpen
              })}
            >
              {pageNav.map(navEl => (
                <Link
                  activeClass={classes.activeMenu}
                  className={classes.menuElement}
                  to={navEl.to}
                  key={navEl.to}
                  spy={true}
                  smooth={true}
                  offset={-20}
                  duration={500}
                  onClick={e => setOpen(false)}
                >
                  <Typography variant='h5' noWrap>
                    {navEl.name}
                  </Typography>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NavBar
