import { red } from '@material-ui/core/colors'
export const themesConfig = {
  palette: {
    type: 'dark',
    primary: {
      light: '#FFFFFF',
      main: '#850B01',
      dark: '#620606'
    },
    secondary: {
      light: '#ffffff', //"#7d7e7e",
      main: '#f28b4b', //#B36601 #850B01
      dark: '#620606',
      contrastText: '#FFFFFF'
    },
    background: {
      paper: '#850B01',
      default: '#000000'
    },
    error: red
  },
  props: {
    MuiSvgIcon: {
      htmlColor: '#FFFFFF'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontFamily: ['Pinyon Script'].join(',')
    }
  }
}

export default themesConfig
