import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { ListOfLanguages } from './ListOfLanguages'
// import { countryToFlag } from '../../../helpers/countries'
import { setLanguage } from '../store/change_language/ChangeLanguageActions'
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 30
  },
  currentLanguage: {
    padding: theme.spacing(1, 2)
    // textDecoration: 'underline dotted'
  },
  language: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(1, 2),
    color: 'white',
    cursor: 'pointer',
    '& :hover': {
      color: theme.palette.primary.main
    }
  },
  flag: {
    fontSize: '25px',
    padding: theme.spacing(0, 1)
  }
}))

const SelectLanguage = (_, context) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    ListOfLanguages[0]
  )
  const state = useSelector(state => ({
    currentLanguage: state.changeCurrentPage.currentLanguage
  }))
  const dispatch = useDispatch()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLanguageChange = (language, event) => {
    event.preventDefault()
    dispatch(setLanguage(language))
    handleClose()
  }
  const open = Boolean(anchorEl)

  useEffect(() => {
    // declare the data fetching function
    const currentLanguage = ListOfLanguages.find(
      lang => lang.value === state.currentLanguage
    )
    setSelectedLanguage(() => currentLanguage)
  }, [state.currentLanguage])

  return (
    <>
      <div className={classes.root}>
        <Button color='default' onClick={handleClick}>
          <span className={classes.currentLanguage}>
            {/* <div className={classes.flag}>{countryToFlag(selectedLanguage.code)}</div> */}
            <Typography variant='body2' noWrap>
              {selectedLanguage.language}
            </Typography>
          </span>
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {ListOfLanguages.map(language => (
            <div
              className={classes.language}
              onClick={e => handleLanguageChange(language.value, e)}
              key={language.value}
            >
              {/* <div className={classes.flag}>{countryToFlag(language.code)}</div> */}
              <Typography variant='body2' noWrap>
                {language.language}
              </Typography>
            </div>
          ))}
        </Popover>
      </div>
    </>
  )
}

export default SelectLanguage
